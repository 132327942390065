<template>
  <div class="result-listing__item lowest-price position-relative">
    <div class="result-listing__item__block overflow-hidden">
      <div class="result-listing__item__block-top">
        <div class="row g-0">
          <div class="col-lg-10 col-md-12 left-col">
            <div class="result-listing__item__block_left h-100">
              <div class="result-listing__item__block_top">
                <div class="comprehensive-third-prty">
                  <div
                    class="comprehensive-third-prty_text"
                    :class="'third-party'"
                  >
                    <template v-if="language === 'ar'">
                    
                      <span> {{ $t("third_party") }} </span>
                    </template>
                    <template v-else>
                       <span> {{ $t("third_party") }} </span>
                    </template>
                  </div>
                </div>
                <div
                  class="result-listing__item__block_top_row d-flex flex-wrap align-items-center"
                >
                  <div class="partner-logo">
                    <img
                      :src="`${domain}${url}/img/${detail.image}`"
                      class="img-fluid"
                    />
                  </div>

                  <div class="partner-content">
                    <div class="partner-content_title">
                      <h5 class="mb-0">{{ detail.policy_name }}</h5>
                    </div>
            
                      <div class="partner-content_bottom d-flex">
                        <div class="partner-content_bottom_item">
                        <p>
                          {{ $t("third_party_damage_limit") }}
                          <span v-if="detail.third_party_damage_limit"
                            >{{
                              $filters.currency(detail.third_party_damage_limit)
                            }}
                          </span>
                          <span v-else
                            >{{ $filters.currency(detail.third_party_limit) }}
                          </span>
                        </p>
                        </div>

                        <div class="partner-content_bottom_item">
                            <p>
                            {{ $t("third_party_liability") }}
                            <span v-if="detail.third_party_liability">
                                {{
                                    detail.third_party_liability
                                }}
                            </span>
                            </p>
                        </div>
                      
                        <div class="partner-content_bottom_item">
                            <p>
                            {{ $t("damage_to_your_vehicle") }}

                            <span v-if="detail.vehicle_damage">
                                {{ detail.vehicle_damage }}
                            </span>
                            </p>
                      
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="result-listing__item__block_medium">
                <div class="row gx-5">
                  <div class="col-md-4 first-col">
                    <div class="listing-ul">
            
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          
          <div class="col-lg-2 col-md-12 right-col">
            <div class="right-buy-now text-center">
              <div class="right-buy-now-top">
                <template v-if="detail.policy_price > 0">
                  <div class="premium-title">
                    <p>{{ $t("total_premium") }}</p>
                  </div>
                  <div class="price-title">
                    <h4 class="mb-0">
                      {{ $filters.currency(detail.policy_price) }}
                    </h4>
                  </div>
                </template>
                  <div class="premium-title" v-if="detail.policy_price === 0" >
                    <p class="contact-for-pricing" v-html="$t('contact_for_pricing')"></p>
                  </div>
                <div class="buy-now-button">
                  <!-- <a href="#" class="btn">Buy now</a> -->
                  <el-button :disabled="detail.policy_price === 0" @click.prevent="BuyNow(detail)" class="btn"
                    ><span class="text-center">
                      {{ $t("buy_now") }}
                    </span></el-button>
                </div>
                <div class="plan-details">
                  <a
                    v-if="detail.bike_terms_url"
                    :href="`${domain}${planUrl}/tc/${detail.bike_terms_url}`"
                    target="_blank"
                    >{{ $t("plan_details") }}
                    <span class="icon-chevron-right"></span>
                  </a>
                </div>
              </div>
             
            </div>
          </div>
          </div>
        </div>
      </div>

   

  </div>
</template>

<script>
import { defineComponent, PropType, ref, computed } from "vue";
import { store } from "@/store";
import Buy from "@/core/services/car/BuyNow";
import router from "@/router";
import { useReCaptcha } from "vue-recaptcha-v3";

export default defineComponent({
  props: {
    detail: {
      type: Object,
      required: true,
    },
    checkCompare: {
      type: Array,
      required: false,
      default: [],
    },
    limit: {
      type: Number,
      required: true,
    },
    numeric: {
      type: Number,
      required: false,
    },
  },

  setup() {
    const captcha = useReCaptcha();

    const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN);
    const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL);
    const planUrl = ref(process.env.VUE_APP_INSURANCE_BIKE_PLAN_URL);
    const price = ref(0);

    // computed
    const compareData = computed(() => {
      return store.state.qoutes.compareData;
    });

    const addValueDriver = (val) => {
      console.log(val);
      if (val.value_personal_driver) {
        val.policy_price += val.personal_accident_driver;
      } else {
        val.policy_price -= val.personal_accident_driver;
      }
    };
    const addValuePassenger = (val) => {
      if (val.value_personal_passenger) {
        val.policy_price += val.personal_accident_passenger;
      } else {
        val.policy_price -= val.personal_accident_passenger;
      }
    };

    const addonAdd = (val, original) => {
      if (val.addonCheck) {
        original.policy_price += val.addonValue.price;
        val.prev_price = val.addonValue.price;
      } else {
        original.policy_price -= val.prev_price;
      }
    };

    const personalPrice = (val, original) => {
      // console.log({val: val, original: original})
      if (original.value_personal_driver) {
        original.policy_price += val;
      } else {
        original.policy_price -= val;
      }
    };

    const personalPassengerPrice = (val, original) => {
      if (original.value_personal_passenger) {
        original.policy_price += val;
      } else {
        original.policy_price -= val;
      }
    };

    // change event
    const changeAddon = (val, original) => {
      original.policy_price += val.addonValue.price;
      original.policy_price -= val.prev_price;
      val.prev_price = val.addonValue.price;
    };

    const checkDisable = computed(() => {
      return store.state.qoutes.isDisable;
    });

    const dataCompare = ref < Array < Object >> [];

    // handleClick
    const BuyNow = async (val) => {
      // load captcha
      await captcha?.recaptchaLoaded();
      // execute reCaptcha
      const token = await captcha?.executeRecaptcha("login");
      // console.log(token);

      const date = new Date();
      const expiryDate = date.setDate(date.getDate() + 30);
      const dateExpiry = new Date(expiryDate).toISOString();
      const data = {
        value: val,
        // uuid_dd: window.localStorage.getItem("fall_back_dd"),
        // uuid_vd: window.localStorage.getItem("fall_back"),
        payment_type: 1,
        order_description: "Online Generated Quotation",
        is_amend: 0,
        policy_sales_agent_id: 1,
        original_price: val.sale_price,
        policy_price: val.policy_price,
        sale_price: val.sale_price,
        language: language.value,
        is_agency_repair: val.agency_repair || 2,
        bai_car_insurance_policy_id: val.id || val.car_insurance_id,
        expiry_date_time: dateExpiry.split("T")[0] + " 23:59:59",
        // manufacturer_id: JSON.parse(
        //   window.localStorage.getItem("customer_detatils")
        // ).manufacturer_id,
        // model_id: JSON.parse(window.localStorage.getItem("customer_detatils"))
        //   .model_id,
        // trim_level_id: JSON.parse(
        //   window.localStorage.getItem("customer_detatils")
        // ).trim_level_id,
        uuid: router.currentRoute.value.query.uuid,
      };
      const driver_id = router.currentRoute.value.query.driver_id;
      if(driver_id) data.driver_id = driver_id
      
      
      if (token) {
        // Buy.getQoutationReference(data)
        // router.push({name: 'SecureChecout'})
        const result = await Buy.getQoutationReference(data);
        if (result.status == 200) {
          // router.push({ name: "SecureChecout" });
          const fullPath = router.currentRoute.value.fullPath
          const path = fullPath.split('?')
          window.location.href = `/bike-secure-checkout?${path[1]}`
        }
      }
    };

    const language = computed(() => {
      return window.localStorage.getItem("language");
    });

    return {
      language,
      price,
      captcha,
      compareData,
      dataCompare,
      checkDisable,
      addValueDriver,
      addValuePassenger,
      BuyNow,
      addonAdd,
      changeAddon,
      personalPrice,
      personalPassengerPrice,
      domain,
      url,
      planUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .result-listing__item .price-tag-top {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: bottom left;
    top: 115px;
    left: 30px;
  }

  .result-listing__item__block-bottom.open {
    display: block;
    height: 100%;
    // opacity: 1;
  }
}

@media (max-width: 600px) {
  .result-listing__item__block-bottom.open {
    display: block;
    height: 100%;
    // opacity: 1;
  }
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #02cd51 !important;
  border-color: #02cd51 !important;
}

.result-listing__item .price-tag-top {
  background: #0061ff;
  color: #fff;
  font-size: 15.5px;
  padding: 15px 18px 15px;
  display: inline-flex;
  text-transform: uppercase;
  font-weight: bold;
}

.result-listing__item__block-bottom {
  display: block;
  height: 0;
  //   opacity: 0;
  transition: height 0.4s;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
}

.result-listing__item__block_left
  .result-listing__item__block_top
  .comprehensive-third-prty
  .third-party {
  background: #8f8f8f !important;
}

.result-listing__item__block_left
  .result-listing__item__block_top
  .comprehensive-third-prty_text {
  display: inline-flex;
  border-radius: 0px 0px 34px 0;
  padding: 3px 15px 2px 18px;
  font-size: 14.5px;
  font-weight: 700;
  color: #fff;
}

.el-select-dropdown__item.selected {
  font-weight: normal !important;
  color: #64666a !important;
}

.contact-for-pricing {
  // font-size: 12px;
  color: #222261;
}
</style>
